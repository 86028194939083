.js-plotly-plot .plotly .modebar-btn {
  display: none !important;
}
.App{
  text-align: center;
}

/* ---------- SIGN IN ---------- */
.sign-in__wrapper {
  background-image: url("./Images//2\ \(2\).png");
   display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.sign-in__backdrop {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.sign-in__wrapper form {
  width: 24rem;
  max-width: 90%;
  z-index: 1;
  animation: showSignInForm 1s;
}

.sign-in__wrapper form img {
  width: 4rem;
}

@keyframes showSignInForm {
  0%,
  30% {
    transform: translate(0, -150%);
  }
  70%,
  90% {
    transform: translate(0, 1rem);
  }
  80%,
  100% {
    transform: translate(0, 0);
  }
}
.profilepic{
  width: 40px;
  /* min-width: 56px; */
  /* height: 60px; */
  border-radius: 50% !important;
}

.react-datepicker-wrapper {
  display: flex !important;
}

.background-image {
  background-image: url('./Images/penumonialogo.png');
  background-size: cover;
  background-repeat: no-repeat;
  
}

.background-shade {
   border: transparent;
  background-color: rgba(0, 0, 0, 0.5);  
  
  border-radius: 2%;
  
   
}
.transparent-card {
  background-color: transparent !important;
 }
 .input-bottom-border {
   border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid rgb(122, 118, 118) !important; /* Change color as needed */
  background-color: transparent !important;
  color: white;
}
.input-bottom-border::placeholder{
   color: rgb(122, 118, 118) !important;
  font-size: medium;
}

 
 

 

/* Add this to your HomeNewDatesRange.css file */

/* Modal styles */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;  
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);  
  overflow: hidden;  
}

.modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  max-height: 80%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.modal-image {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: contain;  
}

 .gallery-image {
  cursor: pointer;
  margin-top: 1px;
  padding: 5px;
}

.gallery-image img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  transition: transform 0.2s;
}

.gallery-image img:hover {
  transform: scale(1.05);
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}
